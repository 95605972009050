body {
    background-color: #222222;
    margin: 0;
    font-family: 'Roboto';
    box-sizing: border-box;
    color: #FFFFFF;
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.text--link {
    color: white;
    font-weight: 700;
    text-decoration: inherit;
}

.hide {
    display: none;
}

nav {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #031B3D 1.18%, #172f51 100%);
    height: 30px;
    width: 100%;
    padding: 30px 0;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
    /* position: fixed; */
  }

.nav--logo {
    height: 50px;
    padding-left: 25px;
}

.nav--title {
    color: white;
    margin: 0;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 700;
    font-size: 22px;
}

.nav--menu {
    display: flex;
    align-items: center;
    padding: 1vw;
    padding-right: 2vw;
}

.nav--menu > li {
    margin-left: 30px;
    font-size: 22px;
}

.nav--hamburger {
    display: none;
    height: 30px;
    width: 30px;
    margin-right: 0.75rem;
    background: transparent;
    border: none;
}

.nav--hamburger:hover {
    cursor: pointer;
}

.hamburger--icon {
    height: 25px;
    width: 25px;
}

.burger--menu {
    background-color: #222222DD;
    margin: 0;
    /* position: fixed; */
    top: 48px;
    width: 100%;
    padding: 15px;
}

.burger--link {
    margin: 0.5rem;
}

.hero--image {
    width: 100%;
}

main {
    margin: 100px;
    margin-right: 25px;
    margin-top: 50px;
}

.main--title {
    color: white;
    font-size: 40px;
}

.main--message {
    color: white;
    font-size: 20px;
    margin-left: 30px;
}

.ranking {
    color: white;
    margin: 100px;
    margin-top: 50px;
}

.ranking--title {
    font-size: 40px;
    margin-bottom: 0;
}

.ranking--disclaimer {
    margin-top: 10px;
    font-style: italic;
    font-size: 15px;
}

.ranking--description {
    font-size: 18px;
}

.ranking--list {
    font-size: 20px;
    list-style: none;
    font-style: bold;
    display: flex;
    align-items: center;
}

.ranking--items {
    margin-bottom: 0;
}

li {
    font-size: 20px;
    list-style: none;
    font-style: bold;
    margin-left: 0px;
    margin-right: 0px;
}

.admin--text {
    font-size: 40px;
    margin: 100px;
    margin-bottom: 0;
    margin-top: 50px;
}

.admin--list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin: 100px;
}

.card {
    margin-right: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card--image {
    width: 12rem;
    border-radius: 100%;
}

.card--name {
    margin-bottom: 0;
}

.card--power {
    margin-bottom: 0;
    margin-top: 0.25rem;
}

.card--contact {
    display: flex;
    align-items: center;
}

.card--mail--icon {
    width: 2rem;
    margin-right: 0.5rem;
    margin-top: 5px;
}

.card--mail--adress {
    color: white;
    font-weight: 700;
    text-decoration: inherit;
    font-size: 16px;
}



footer {
    position: fixed;
    bottom: 0;
    background-color: #031B3D;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
}

.footer--mail--icon {
    width: 40px;
    height: 40px;
    margin-left: 80px;
    margin-top: 5px;
}

.footer--mailadress {
    margin-left: 20px;
    margin-right: auto;
    color: white;
}

.footer--credits {
    margin-right: 40px;
}

@media screen and (max-width: 800px) {
    nav {
        height: 40px;
        padding: 4px;
      }
    
    .nav--logo {
        height: 30px;
    }
    
    .nav--title {
        margin-left: 2vw;
        font-size: 18px;
    }

    .nav--menu {
        display: none;
    }
/* 
    .nav--menu > li {
        font-size: 18px;
        margin-left: 15px;
    } */

    .nav--hamburger {
        display: block;
    }

    main {
        margin: 1rem;
        margin-left: 5vw;
    }
    
    .main--title {
        font-size: 35px;
        margin: 0;
        margin-bottom: 0.5vh;
    }
    
    .main--message {
        font-size: 15px;
        margin-left: 0vw;
        margin: 0;
    }

    .ranking {
        margin: 5vw;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .ranking--title {
        font-size: 28px;
        margin-bottom: 0;
    }
    
    .ranking--disclaimer {
        margin-top: 5px;
        font-size: 12px;
    }
    
    .ranking--description {
        font-size: 15px;
    }
    
    .ranking--list{
        font-size: 18px;
        margin-left: 0;
        margin-right: 0;
    }

    li {
        font-size: 18px;
        margin-left: 0;
        margin-right: 0;
    }

    .admin--list {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        /* flex-wrap: nowrap; */
    }

    .admin--text {
        text-align: center;  
        font-size: 35px; 
        margin-bottom: 25px;
    }

    .card {
        width: 100%;
        margin-bottom: 50px;
    }

    .card--image {
        width: 6rem;
    }

    .card--name {
        font-size: 18px;
    }

    .card--power {
        font-size: 15px;
    }
    
    .card--mail--icon {
        width: 1rem;
    }
    
    .card--mail--adress {
        font-size: 15px;
    }

    footer {
        height: 40px;
    }

    .footer--mail--icon {
        width: 25px;
        height: 25px;
        margin-left: 3vw;
    }

    .footer--mailadress {
        margin-left: 1vw;
        font-size: 10px;
    }
    
    .footer--credits {
        margin-right: 3vw;
        font-size: 10px;
    }
}